import { Box } from "@mui/material";
import { ICarRequest } from "src/types/car-request";
import { TicketInfoBox } from "../ticket-info-box";

const TicketInfo = ({ data }: { data: ICarRequest }) => {
  return (
    <Box mt={"47px"}>
      <TicketInfoBox data={data} hiddenPaymentStatus={!data.paymentInfo} />
    </Box>
  );
};

export default TicketInfo;
