import { Stack, Button, Typography } from "@mui/material";
import Logo from "src/assets/svg-icons/arsann-logo.svg";
import GlobalIcon from "src/assets/svg-icons/global.svg";
import { useLocale } from "src/hooks/use-locale";

const TobBar = () => {
  const { locale, setLocale } = useLocale();

  const handleLanguageChange = () => {
    setLocale(locale === "en" ? "ar" : "en");
  };

  return (
    <Stack pt={1.5} direction={"row"} justifyContent={"space-between"}>
      <img src={Logo} width={42} alt="" />
      <Button
        size="small"
        sx={{ textTransform: "unset" }}
        onClick={handleLanguageChange}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <img width={20} src={GlobalIcon} alt="" />
          <Typography color={"textPrimary"} fontSize={"13px"} fontWeight={700}>
            {locale === "en" ? "العربية" : "English"}
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};

export { TobBar };
