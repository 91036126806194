import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { FormFields } from "../../constants";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import {
  useGetKAFDLocations,
  useInitiateKAFDBookingMutation,
} from "src/api/kafd-prebooking";
import LocationsMap from "../map";
import downArrowIcon from "src/assets/svg-icons/down-arrow.svg";

const policyPoints = ["policyPoint1", "policyPoint2", "policyPoint3"];

const BookingForm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openDate, setOpenDate] = useState(false);
  const [openArrivingTime, setOpenArrivingTime] = useState(false);
  const [openLeavingTime, setOpenLeavingTime] = useState(false);
  const [sameAsGuest, setSameAsGuest] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [price, setPrice] = useState(0);
  const [extended, setExtended] = useState(false);

  // APIS
  const { mutate, status, data, error, isPending } =
    useInitiateKAFDBookingMutation();

  const { data: locationsData } = useGetKAFDLocations();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useFormContext<FormFields>();

  const validateDateTimeInPast = (arrivingDate: Date, arrivingTime: Date) => {
    // Combine the date and time into a single moment object
    const arrivingDateTime = moment(arrivingDate).set({
      hour: arrivingTime.getHours(),
      minute: arrivingTime.getMinutes(),
      second: arrivingTime.getSeconds(),
      millisecond: arrivingTime.getMilliseconds(),
    });

    // Get the current date and time
    const now = moment();

    // Check if the arriving date and time is in the past
    return arrivingDateTime.isBefore(now);
  };

  const calculatePrice = () => {
    const service = selectedLocation?.services[0];
    if (service.pricingType == "free") {
      return 0;
    }
    const arrivalTime = getValues("arrivingTime");
    const leaveTime = getValues("leavingTime");
    if (service.pricingType === "custom") {
      // const now = new Date();
      const hrDiff =
        Math.abs(leaveTime.getTime() - arrivalTime.getTime()) /
        (1000 * 60 * 60);
      const additionalHours = Math.ceil(
        hrDiff - parseInt(service.customStartHrs)
      );
      const exceedingPriceValue: number =
        service.customExceedingPriceType === "allHours" ||
        service.customExceedingPriceType === "fixed"
          ? parseInt(service.customExceedingPrice)
          : additionalHours * parseInt(service.customExceedingPrice);

      return hrDiff > service.customStartHrs
        ? parseInt(service.customPrice) + exceedingPriceValue
        : service.customPrice || 0;
    } else if (service.pricingType === "hourly") {
      const hrDiff =
        Math.abs(leaveTime.getTime() - arrivalTime.getTime()) /
        (1000 * 60 * 60);
      const additionalHours = Math.ceil(hrDiff);
      console.log(
        "🚀 ~ calculateNormalValetTicketPriceV3 ~ additionalHours:",
        additionalHours
      );
      return additionalHours * service.hourlyPrice;
    } else {
      return service.fixedPrice || 0;
    }
  };

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    console.log({ data });
    if (validateDateTimeInPast(data.arrivingDate, data.arrivingTime)) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("canNotPickPrevTime"),
        },
      ]);
      return;
    }

    if (data.leavingTime <= data.arrivingTime) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("leavingTimeMustAfterArrivalTime"),
        },
      ]);
      return;
    }

    const arrivingDateTime = moment(data.arrivingDate).set({
      hour: data.arrivingTime.getHours(),
      minute: data.arrivingTime.getMinutes(),
      second: 0,
      millisecond: 0,
    });

    const leavingDateTime = moment(data.arrivingDate).set({
      hour: data.leavingTime.getHours(),
      minute: data.leavingTime.getMinutes(),
      second: 0,
      millisecond: 0,
    });

    mutate({
      locationId: data.location,
      hostName: data.hostName,
      hostIsoCode: "SA",
      hostCountryCode: "966",
      hostMobileNumber: data.hostNumber,
      customerName: data.guestName,
      customerIsoCode: "SA",
      customerCountryCode: "966",
      customerMobileNumber: data.guestNumber,
      arrivingTime: arrivingDateTime.valueOf(),
      leavingTime: leavingDateTime.valueOf(),
    });
  };

  const handleOpenDatePicker = () => {
    setOpenDate(true);
  };

  const handleCloseDatePicker = () => {
    setOpenDate(false);
  };

  const handleOpenArrivingTimePicker = () => {
    setOpenArrivingTime(true);
  };

  const handleCloseArrivingTimePicker = () => {
    setOpenArrivingTime(false);
  };

  const handleOpenLeavingTimePicker = () => {
    setOpenLeavingTime(true);
  };

  const handleCloseLeavingTimePicker = () => {
    setOpenLeavingTime(false);
  };

  useEffect(() => {
    if (selectedLocation && watch("arrivingTime") && watch("leavingTime")) {
      const price = calculatePrice();
      console.log({ price });
      setPrice(price ?? 0);
    }
  }, [selectedLocation, watch("arrivingTime"), watch("leavingTime")]);

  useEffect(() => {
    if (!!getValues("location")) {
      const location = locationsData?.data.find((loc: any) => {
        return loc.locationId === getValues("location");
      });
      setSelectedLocation(location);
    }
  }, [watch("location")]);

  console.log({ selectedLocation });

  useEffect(() => {
    if (status === "success") {
      window.location.href = data?.data.paymentUrl;
      return;
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  useEffect(() => {
    if (sameAsGuest) {
      setValue("guestName", getValues("hostName"));
      setValue("guestNumber", getValues("hostNumber"));
    }
  }, [sameAsGuest, watch("hostName"), watch("hostNumber")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box borderRadius={"12px"} overflow={"hidden"}>
        <Box bgcolor={"#272727"} padding={{ xs: "30px 16px", md: "30px 47px" }}>
          <Typography color={"primary"} fontSize={"24px"} fontWeight={500}>
            {t("bookingInfo")}
          </Typography>
          <Typography mb={3}>{t("fillDetailsBelow")}</Typography>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={8}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
            >
              <Box>
                <Typography sx={{ mb: "10px" }}>
                  {t("selectLocation")}
                </Typography>
                <FormControl fullWidth error={!!errors.location} size="medium">
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ bgcolor: "#222" }}
                      >
                        <MenuItem value="" disabled>
                          {t("selectLocation")}
                        </MenuItem>
                        {locationsData?.data.map((location: any) => {
                          return (
                            <MenuItem
                              key={location.locationId}
                              value={location.locationId}
                            >
                              {i18n.language === "en"
                                ? location.locationName
                                : location.locationNameAr}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {t(`${errors.location?.message || ""}`)}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box>
                <Typography sx={{ mb: "10px" }}>{t("arrivingDate")}</Typography>
                <div>
                  <Controller
                    name="arrivingDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.arrivingDate,
                            helperText: t(
                              `${errors.arrivingDate?.message || ""}`
                            ),
                            onClick: handleOpenDatePicker,
                          },
                        }}
                        format={"dd/MM/yyyy"}
                        open={openDate}
                        onOpen={handleOpenDatePicker}
                        onClose={handleCloseDatePicker}
                        sx={{ bgcolor: "#222" }}
                      />
                    )}
                  />
                </div>
              </Box>

              <Stack direction={{ xs: "column", sm: "row" }} gap={3}>
                <Box width={"100%"}>
                  <Typography sx={{ mb: "10px" }}>
                    {t("arrivingTime")}
                  </Typography>
                  <div>
                    <Controller
                      name="arrivingTime"
                      control={control}
                      render={({ field }) => (
                        <DesktopTimePicker
                          {...field}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.arrivingTime,
                              helperText: t(
                                `${errors.arrivingTime?.message || ""}`
                              ),
                              onClick: handleOpenArrivingTimePicker,
                            },
                          }}
                          ampm={true}
                          format={
                            i18n.language === "en" ? "hh:mm aa" : "hh:mmaa"
                          }
                          open={openArrivingTime}
                          onOpen={handleOpenArrivingTimePicker}
                          onClose={handleCloseArrivingTimePicker}
                          sx={{ bgcolor: "#222" }}
                        />
                      )}
                    />
                  </div>
                </Box>

                <Box width={"100%"}>
                  <Typography sx={{ mb: "10px" }}>
                    {t("leavingTime")}
                  </Typography>
                  <div>
                    <Controller
                      name="leavingTime"
                      control={control}
                      render={({ field }) => (
                        <DesktopTimePicker
                          {...field}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.leavingTime,
                              helperText: t(
                                `${errors.leavingTime?.message || ""}`
                              ),
                              onClick: handleOpenLeavingTimePicker,
                            },
                          }}
                          ampm={true}
                          format={
                            i18n.language === "en" ? "hh:mm aa" : "hh:mmaa"
                          }
                          open={openLeavingTime}
                          onOpen={handleOpenLeavingTimePicker}
                          onClose={handleCloseLeavingTimePicker}
                          sx={{ bgcolor: "#222" }}
                        />
                      )}
                    />
                  </div>
                </Box>
              </Stack>
              {/* price */}
              <Box>
                <Typography mb={"10px"}>{t("valetServiceFees")}</Typography>
                <Box bgcolor={"#38383880"} p={"20px"} borderRadius={"12px"}>
                  <Typography>
                    {t("price")}:{" "}
                    {i18n.language === "en"
                      ? `${t("sar")} ${price}`
                      : `${price} ${t("sar")}`}
                  </Typography>
                </Box>
                <Typography color={"primary"} mt={"10px"}>
                  {t("PaidFeeNotRefundableAndExtraFeesMightApplied")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} order={{ xs: -1, md: 0 }}>
              <LocationsMap locations={locationsData?.data || []} />
            </Grid>
          </Grid>

          {/* divider */}
          <Box bgcolor={"#383838"} width={"100%"} height={"1px"} my={4} />

          <Typography color={"primary"} fontSize={"24px"} fontWeight={500}>
            {t("hostGuestInfo")}
          </Typography>
          <Typography mb={3}>{t("fillDetailsBelow")}</Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography sx={{ mb: "10px" }}>{t("hostName")}</Typography>
                <Controller
                  name="hostName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder={t("name")}
                      {...field}
                      error={!!errors.hostName}
                      helperText={t(`${errors.hostName?.message || ""}`)}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      sx={{ bgcolor: "#222" }}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography sx={{ mb: "10px" }}>{t("hostMobile")}</Typography>
                <div dir="ltr">
                  <Controller
                    name="hostNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        placeholder={t("mobileNumber")}
                        {...field}
                        error={!!errors.hostNumber}
                        helperText={t(`${errors.hostNumber?.message || ""}`)}
                        fullWidth
                        size="medium"
                        variant="outlined"
                        sx={{ bgcolor: "#222" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                sx={{
                                  direction:
                                    i18n.language === "en" ? "ltr" : "rtl",
                                }}
                              >
                                +966
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} display={"flex"} alignItems={"center"}>
              <Stack pt={3} pl={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={sameAsGuest}
                      onChange={(e) => {
                        setSameAsGuest(e.target.checked);
                      }}
                    />
                  }
                  label={t("GuestInfoTheSame")}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography sx={{ mb: "10px" }}>{t("guestName")}</Typography>
                <Controller
                  name="guestName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder={t("name")}
                      {...field}
                      error={!!errors.guestName}
                      helperText={t(`${errors.guestName?.message || ""}`)}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      sx={{ bgcolor: "#222" }}
                      disabled={sameAsGuest}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography sx={{ mb: "10px" }}>{t("guestMobile")}</Typography>
                <div dir="ltr">
                  <Controller
                    name="guestNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        placeholder={t("mobileNumber")}
                        {...field}
                        error={!!errors.guestNumber}
                        helperText={t(`${errors.guestNumber?.message || ""}`)}
                        fullWidth
                        size="medium"
                        variant="outlined"
                        sx={{ bgcolor: "#222" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                sx={{
                                  direction:
                                    i18n.language === "en" ? "ltr" : "rtl",
                                }}
                              >
                                +966
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        disabled={sameAsGuest}
                      />
                    )}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>

          <Stack
            direction={"row"}
            alignItems={"center"}
            mt={3}
            mb={2}
            gap={4}
            sx={{ cursor: "pointer" }}
            onClick={() => setExtended((prev) => !prev)}
          >
            <Typography color={"primary"} fontSize={"20px"} fontWeight={500}>
              {t("usagePolicy")}
            </Typography>
            <Box sx={{ transform: extended ? "scaleY(-1)" : "unset" }}>
              <img src={downArrowIcon} alt="" />
            </Box>
          </Stack>

          {extended &&
            policyPoints.map((point) => (
              <Stack direction={"row"} gap={1.2} mb={0.4}>
                <Box
                  width={"5px"}
                  height={"5px"}
                  bgcolor={"#D0D0D0"}
                  mt={"10px"}
                />
                <Typography color={"#D0D0D0"} lineHeight={"26px"}>
                  {t(point)}
                </Typography>
              </Stack>
            ))}

          <Stack mt={"62px"}>
            <LoadingButton
              size="large"
              variant="contained"
              sx={{
                mx: "auto",
                minWidth: { xs: "100%", sm: "310px" },
                fontSize: "16px",
              }}
              type="submit"
              loading={isPending || !!data}
            >
              {t("bookNow")}
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </form>
  );
};

export default BookingForm;
