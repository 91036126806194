import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import WalletIcon from "src/assets/svg-icons/wallet.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IProps {}

const PaymentFailed: React.FC<IProps> = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToBooking = () => {
    navigate(`/kafd-pre-booking/`);
  };

  return (
    <Box borderRadius={"12px"} overflow={"hidden"}>
      <Stack
        bgcolor={"#272727"}
        padding={"30px 113px 100px 113px"}
        alignItems={"center"}
      >
        <img src={WalletIcon} alt="" />
        <Typography
          mb={"60px"}
          color={"primary"}
          fontSize={"24px"}
          fontWeight={700}
          textTransform={"uppercase"}
          align="center"
        >
          {t("paymentFailed")}!
        </Typography>
        <Typography mb={2} align="center">
          {t("ifYouWantTryAgain")}
        </Typography>
        <Button
          sx={{ minWidth: "210px" }}
          variant="contained"
          size="large"
          onClick={navigateToBooking}
        >
          {t("clickHere")}
        </Button>
      </Stack>
    </Box>
  );
};

export default PaymentFailed;
