import * as Yup from "yup";

export const defaultSteps = [
  {
    id: "bookingForm",
    fields: ["time", "gate"],
  },
  {
    id: "requested",
    fields: [],
  },
  {
    id: "cancelled",
    fields: [],
  },
];

export interface FormFields extends Yup.InferType<typeof schema> {}

// validations
export const schema = Yup.object().shape({
  // booking form
  location: Yup.string().required("Required"),
  hostName: Yup.string()
    .required("Required")
    .max(250, "cannotExceed250Characters"),
  hostNumber: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "MobileNumberEnglishDigitsOnly") // Ensures only English digits
    .matches(/^5\d{8}$/, "MobileNumber9digitsStartWith5"),
  guestName: Yup.string()
    .required("Required")
    .max(250, "cannotExceed250Characters"),
  guestNumber: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "MobileNumberEnglishDigitsOnly") // Ensures only English digits
    .matches(/^5\d{8}$/, "MobileNumber9digitsStartWith5"),
  arrivingDate: Yup.date().required("Required"),
  arrivingTime: Yup.date().required("Required"),
  leavingTime: Yup.date().required("Required"),
});
