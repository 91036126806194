import { Box, Typography } from "@mui/material";
import React from "react";
import i18n from "src/i18n";

interface IProps {
  label: string;
  value: string;
}

const ItemBox: React.FC<IProps> = ({ label, value }) => {
  return (
    <Box
      bgcolor={"#222222"}
      borderRadius={"6px"}
      p={3}
      display={"flex"}
      gap={"20px"}
      flexGrow={1}
      minWidth={"50%"}
    >
      <Typography minWidth={"100px"} fontSize={"14px"} color={"#737373"}>
        {label}
      </Typography>
      <Typography sx={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
        {value}
      </Typography>
    </Box>
  );
};

export default ItemBox;
