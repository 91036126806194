import { Stack, Button, Typography, Box } from "@mui/material";
import Logo from "src/assets/svg-icons/arsann-logo.svg";
import KAFDLogo from "src/assets/svg-icons/kafd-logo.svg";
import GlobalIcon from "src/assets/svg-icons/global.svg";
import { useLocale } from "src/hooks/use-locale";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const TobBar = () => {
  const { locale, setLocale } = useLocale();
  const theme = useTheme();
  const matchesSmallUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleLanguageChange = () => {
    window.location.reload();
    setLocale(locale === "en" ? "ar" : "en");
  };

  const Arabic = matchesSmallUp ? "العربية" : "ع";
  const English = matchesSmallUp ? "English" : "En";

  return (
    <Stack
      my={{xs: "30px",md :"50px"}}
      p={{xs: "8px 20px",md:"8px 47px"}}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      bgcolor={"#27272740"}
      borderRadius={"12px"}
    >
      <Box borderRadius={"20px"} display={"flex"} gap={"20px"}>
        <img src={KAFDLogo} height={64} alt="" />
        <Box bgcolor={"#fff"} height={"64px"} width={"1.5px"} />
        <img src={Logo} height={64} alt="" />
      </Box>

      <Button
        sx={{
          textTransform: "unset",
          bgcolor: "#383838",
          minWidth: { xs: "unset", md: "141px" },
        }}
        onClick={handleLanguageChange}
        size="medium"
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <img height={17} src={GlobalIcon} alt="" />
          <Typography color={"textPrimary"}>
            {locale === "en" ? Arabic : English}
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};

export { TobBar };
