import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Box } from "@mui/material";
import MapPinIcon from "src/assets/svg-icons/map_pin.svg";
import { useTranslation } from "react-i18next";

interface IProps {
  locations: any[];
}

const containerStyle = {
  height: "400px",
};

const LocationsMap: React.FC<IProps> = ({ locations }) => {
  const { i18n } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBjGZRBq1seDBmbq62CMyV2VkpRrOwNh_Q",
  });

  return isLoaded ? (
    <Box borderRadius={"8px"} overflow={"hidden"}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: locations[1]?.locationLatitude,
          lng: locations[1]?.locationLongitude,
        }}
        zoom={16}
        options={{
          // disableDefaultUI: true,
          // fullscreenControl: true,
          // draggable: false,
          // scrollwheel: false,
          // disableDoubleClickZoom: true,
          // gestureHandling: "none",
          // zoomControl: false,
          // clickableIcons: false
        }}
      >
        <>
          {locations.map((loc) => {
            return (
              <Marker
                label={{
                  text:
                    i18n.language === "en"
                      ? loc.locationName
                      : loc.locationNameAr,
                  color: "#464646",
                  fontSize: "13px",
                  fontWeight: "500",
                  className: "marker-label-custom",
                }}
                position={{
                  lat: loc.locationLatitude,
                  lng: loc.locationLongitude,
                }}
                options={{
                  icon: {
                    url: MapPinIcon,
                    scaledSize: new window.google.maps.Size(30, 30), // Adjust size as needed
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(25, 50),
                    
                  },
                }}
              />
            );
          })}
        </>
      </GoogleMap>
    </Box>
  ) : (
    <></>
  );
};

export default LocationsMap;
