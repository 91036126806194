import { Box, Button, Drawer, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FailedIcon from "src/assets/svg-icons/failed.svg";

const PaymentFailed = ({ open, handleClose }: any) => {
  const { t } = useTranslation();

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "35px",
        },
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"392px"} textAlign={"center"} py={4}>
          <img src={FailedIcon} alt="" />
          <Typography fontSize={"24px"} fontWeight={600} mt={0} mb={0.5}>
            {t("paymentFailed")}
          </Typography>
          <Typography mb={2} maxWidth={"312px"}>
            {t("yourPaymentFailedTryAnother")}
          </Typography>
          <Button fullWidth variant="contained" onClick={handleClose}>
            {t("okay")}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default PaymentFailed;
