import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SuccessIcon from "src/assets/svg-icons/success.svg";
import { ICarRequest } from "src/types/car-request";
import { TicketInfoBox } from "../ticket-info-box";

const RequestedSuccessfully = ({
  data,
  setCurrentStep,
  isPartialPaid,
}: {
  data: ICarRequest;
  setCurrentStep: Function;
  isPartialPaid: boolean;
}) => {
  const { t } = useTranslation();

  const handlePayNow = () => {
    setCurrentStep(2); // go to payment step
  };

  return (
    <Box pb={3}>
      <Stack alignItems={"center"} mb={0}>
        <img width={130} src={SuccessIcon} alt="" />
        <Typography mt={1} mb={1.2} fontSize={"24px"} fontWeight={600}>
          {t("requestedSuccessfully")}
        </Typography>
        <Typography mb={2} align="center">
          {t("after30MinutesExtraFees")}
        </Typography>
      </Stack>
      <TicketInfoBox data={data} />
      {isPartialPaid && (
        <Button variant="contained" sx={{ mt: "20px" }} onClick={handlePayNow}>
          {t("payNow")}
        </Button>
      )}
    </Box>
  );
};

export default RequestedSuccessfully;
