import { Stack, Box, Typography } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import StatusChip from "src/shared/components/status-chip";
import { ICarRequest } from "src/types/car-request";

const TicketInfoBox = ({
  data,
  hiddenPaymentStatus = false,
}: {
  data: ICarRequest;
  hiddenPaymentStatus?: boolean;
}) => {
  const {
    ticketNumber,
    plateNumber,
    checkInTime,
    enableFreeLocation,
    paymentInfo,
    price,
    plateLetter,
  } = data || {};
  const isFreeTicket = enableFreeLocation || price === 0;

  const paymentStatus =
    !isFreeTicket && !!paymentInfo && paymentInfo.unpaidAmount <= 0
      ? "Paid"
      : !isFreeTicket &&
        !!paymentInfo &&
        paymentInfo.amount > 0 &&
        paymentInfo.unpaidAmount > 0
      ? "Partially Paid"
      : "Unpaid";

  return (
    <>
      <Stack alignItems={"center"} mb={4.5}>
        <Box bgcolor={"white"} borderRadius={"6px"} pt={1.5} pb={1} px={1}>
          <QRCodeSVG value={ticketNumber} />
        </Box>
      </Stack>

      <Stack gap={1.5}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("ticketNum")}
          </Typography>
          <Typography
            color={"textPrimary"}
            fontWeight={700}
            style={{ direction: "ltr" }}
          >
            {ticketNumber}
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("vehicleNum")}
          </Typography>
          <Typography color={"textPrimary"} fontWeight={700}>
            {!!plateLetter || !!plateNumber
              ? `${plateLetter || ""}${plateNumber || ""}`
              : "--"}
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("date")}
          </Typography>
          <Typography color={"textPrimary"} fontWeight={700}>
            {checkInTime ? moment(checkInTime).format("DD/MM/YYYY") : "--"}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("fees")}
          </Typography>
          {isFreeTicket ? (
            <StatusChip label="Free" />
          ) : (
            <Typography color={"textPrimary"} fontWeight={700}>
              {price} {t("sar")}
            </Typography>
          )}
        </Stack>

        {!isFreeTicket &&
          !hiddenPaymentStatus &&
          paymentStatus !== "Partially Paid" && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography color={"textPrimary"} fontSize={"14px"}>
                {t("paymentStatus")}
              </Typography>
              <StatusChip
                label={paymentStatus === "Paid" ? t("paid") : t("unpaid")}
                variant={paymentStatus === "Paid" ? "success" : "error"}
              />
            </Stack>
          )}
        {paymentStatus === "Partially Paid" && (
          <Box mt={1.5} textAlign={"center"}>
            <Typography color={"#868686"} fontSize={"14px"} fontWeight={500}>
              {t("totalPaidFees")}
            </Typography>
            <Typography color={"#4EBE86"} fontSize={"25px"} fontWeight={700}>
              {paymentInfo.amount} {t("sar")}
            </Typography>
            <Typography color={"#868686"} fontSize={"14px"} fontWeight={500}>
              {t("extraFeesApplied")}
            </Typography>
            <Typography color={"#FF4A4A"} fontSize={"25px"} fontWeight={700}>
              {paymentInfo.unpaidAmount} {t("sar")}
            </Typography>
          </Box>
        )}
      </Stack>
    </>
  );
};

export { TicketInfoBox };
