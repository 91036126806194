import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShieldIcon from "src/assets/svg-icons/shield.svg";

const PolicyPopup = ({ open, handleClose }: any) => {
  const { t } = useTranslation();

  const points = ["policyPoint1", "policyPoint2", "policyPoint3"];

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "35px",
          maxWidth: { xs: "100%", sm: "375px" },
          mx: "auto",
        },
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"392px"} py={4}>
          <Stack mb={2} direction={"row"} gap={"10px"} alignItems={"center"}>
            <img width={21} height={25} src={ShieldIcon} alt="" />
            <Typography color={"primary"} fontWeight={600} mt={0} mb={0.5}>
              {t("arsannUsagePolicy")}
            </Typography>
          </Stack>

          {points.map((point) => (
            <Stack direction={"row"} gap={1.2} mb={0.4}>
              <Box
                width={"5px"}
                height={"5px"}
                bgcolor={"#D0D0D0"}
                mt={"10px"}
              />
              <Typography
                color={"#D0D0D0"}
                lineHeight={"22px"}
                maxWidth={"312px"}
                fontSize={"14px"}
              >
                {t(point)}
              </Typography>
            </Stack>
          ))}

          <Button
            fullWidth
            variant="contained"
            onClick={handleClose}
            sx={{ mt: 2.5 }}
          >
            {t("agreeContinue")}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default PolicyPopup;
